<template>
  <el-dialog
    title="注册/开通电子签"
    :visible.sync="visible"
    :show-close="false"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
  >
    <el-form ref="formRef" :model="form" :inline="true" size="small">
      <el-form-item label="个人姓名" prop="name" required>
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="证件类型" prop="idNoType" required>
        <el-select v-model="form.idNoType" placeholder="请选择" :disabled="true">
          <el-option v-for="item in idTypeList" :key="item.code" :label="item.label" :value="item.value"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="证件号" prop="idNo" required>
        <el-input v-model="form.idNo"></el-input>
      </el-form-item>
    </el-form>
    <div>
      <el-checkbox v-model="confirmAgreement" :true-label="1" :false-label="0"> </el-checkbox>
      <span class="read"
        >我已阅读并同意 <a @click="toRead('cert')">《认证服务协议》</a>、
        <a @click="toRead('digi')">《数字证书服务协议》</a>和
        <a @click="toRead('priv')">《隐私政策》</a>
      </span>
    </div>
    <span slot="footer">
      <el-button type="primary" @click="onConfirm">注册/开通电子签</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { eSignRegisterApi } from '@/api';

export default {
  name: 'RegistrateSignature',
  data() {
    return {
      visible: false,
      idTypeList: [
        { value: 1, label: '大陆身份证', code: 'CRED_PSN_CH_IDCARD' },
        { value: 7, label: '台湾来往大陆通行证', code: 'CRED_PSN_CH_TWCARD' },
        { value: 6, label: '澳门来往大陆通行证', code: 'CRED_PSN_CH_MACAO' },
        { value: 6, label: '香港来往大陆通行证', code: 'CRED_PSN_CH_HONGKONG' },
        { value: 3, label: '护照', code: 'CRED_PSN_PASSPORT' },
      ],
      rules: {
        name: [{ required: true, message: '请输入个人姓名', trigger: 'blur' }],
        idNo: [{ required: true, message: '请输入证件号', trigger: 'blur' }],
      },
      form: {
        name: '',
        idNoType: 1,
        idNo: '',
      },
      confirmAgreement: 1,
    };
  },
  methods: {
    onConfirm() {
      if (!this.confirmAgreement) {
        this.$message.info('请先阅读并同意相关协议');
        return;
      }
      this.$refs.formRef?.validate((valid) => {
        if (valid) {
          eSignRegisterApi({
            ...this.form,
            accountId: window.localStorage.getItem('accountId'),
            confirmAgreement: this.confirmAgreement,
          }).then((res) => {
            if (res?.code === '0') {
              this.$message.success('注册/开通电子签成功');
              window.sessionStorage.setItem('esignAccountId', res?.data);
              this.visible = false;
            } else {
              this.$message.error(res?.message);
            }
          });
        } else {
          return false;
        }
      });
    },

    toRead(type) {
      let url = '';
      if (type === 'cert') {
        url = 'https://zt-public-cos.medbanks.cn/storage/r9x39oaziiwa0lmwvov7.pdf';
      } else if (type === 'digi') {
        url = 'https://zt-public-cos.medbanks.cn/storage/x9e5xjwoe23fkzoe2zwr.pdf';
      } else if (type === 'priv') {
        url = 'https://zt-public-cos.medbanks.cn/storage/sk76lgkwzuv0xw3b4lp1.pdf';
      }
      window.open(url, '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
.read {
  margin-left: 10px;
  a {
    color: #1989fa;
  }
}
</style>
