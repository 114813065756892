var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.supervisionSwitchLoading),expression:"supervisionSwitchLoading"}],staticClass:"med-container"},[_c('div',{staticClass:"med-content"},[(_vm.expandMuneBoolen)?_c('div',{staticClass:"left-wrapper"},[_c('div',{staticClass:"name",staticStyle:{"text-align":"center"}},[_vm._v("思派互联网医院")]),_c('el-menu',{staticClass:"med-menu",attrs:{"default-active":_vm.routerIndex,"background-color":"#002140","text-color":"#fff","active-text-color":"#fff","collapse":_vm.isMenuHide,"unique-opened":_vm.isSingleShow},on:{"select":_vm.handleSelect}},[_c('SidebarMenu',{attrs:{"menu-data":_vm.menuData,"default-active":_vm.routerIndex}})],1)],1):_vm._e(),_c('div',{staticClass:"right-wrapper",class:{ 'no-menu': !_vm.expandMuneBoolen }},[(_vm.expandMuneBoolen)?_c('common-header',{attrs:{"user-info":_vm.userInfo}}):_vm._e(),(_vm.expandMuneBoolen && _vm.breadcrumbData && _vm.breadcrumbData.length > 0)?_c('el-breadcrumb',{staticClass:"med-breadcrumb"},[_vm._l((_vm.breadcrumbData),function(item,index){return [(_vm.breadcrumbData.length === index + 1)?_c('el-breadcrumb-item',{key:index},[_vm._v(" "+_vm._s(item.label)+" ")]):_c('el-breadcrumb-item',{key:index + 1,attrs:{"to":{ path: item.path, query: item.query }}},[_vm._v(" "+_vm._s(item.label)+" ")])]})],2):_vm._e(),_c('div',{staticClass:"content_box",class:{
          'no-padding': _vm.getPadding,
          'reset-Padding': _vm.restPadding,
          'btn-fix-padding': _vm.fixPadding,
          noPaddingBottom: !_vm.expandMuneBoolen,
        }},[_c('div',{class:[
            { 'has-breadcrumb': _vm.breadcrumbData.length > 0 },
            'right-content',
            { 'no-padding': _vm.getPadding },
            { 'reset-no-padding': _vm.fixPadding } ]},[_c('keep-alive',{attrs:{"include":['MemberList', 'ServiceManager', 'FaceDiagonse']}},[_c('router-view')],1)],1)])],1)]),_c('RegistrateSignature',{ref:"registrateSignatureRef"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }